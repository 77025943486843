<template>
  <div
    class="my-header"
    :class="activeId === 0 ? 'isHome' : ''"
    ref="header"
  >
    <div class="my-header-cover" :class="isScroll ? 'scroll-header' : ''">
      <div class="logo">
        <div class="img">
          <img :src="logoImg" alt="" @click="setTag(item)" v-for="(item, index) in navs.slice(0,1)" :key="item.id"/>
        </div>
      </div>
      <div class="nav">
        <div
          class="nav-item"
          v-for="(item, index) in navs"
          :key="item.id"
          :class="{ active: activeId === item.id }"
          @click="setTag(item)"
          @mouseenter="enter(item, index)"
          @mouseleave="leave(item)"
        >
          <span>{{ item.title }}</span>
          <div class="line"></div>
        </div>
      </div>

      <div
        class="second-header"
        v-show="secondShow"
        @mouseenter="enterSecond"
        @mouseleave="leaveSecond"
      >
        <div
          class="second-nav"
          v-for="(item, index) in seconds"
          :key="index"
          @click="route(item)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let scrollTop = 0;
export default {
  name: "MyHeader",
  data() {
    return {
      activeId: 0,
      navs: [
        {
          id: 0,
          title: "首页",
          url: "/home",
        },
        {
          id: 1,
          title: "爱情地产",
          url: "/company/about",
          children: [
            {
              title: "集团简介",
              url: "/company/about",
            },
            {
              title: "品牌理念",
              url: "/company/concept",
            },
            {
              title: "战略布局",
              url: "/company/layout",
            },
            {
              title: "发展历程",
              url: "/company/course",
            },
            {
              title: "品牌荣誉",
              url: "/company/develop",
            },
          ],
        },
        {
          id: 2,
          title: "产品创新",
          url: "/product",
        },
        {
          id: 3,
          title: "业务板块",
          url: "/business/dwell",
          children: [
            {
              title: "地产开发",
              url: "/business/dwell",
            },
            {
              title: "商业运营",
              url: "/business/operate",
            },
            {
              title: "物业服务",
              url: "/business/estate",
            },
          ],
        },
        {
          id: 4,
          title: "新闻中心",
          url: "/news/group-news",
          children: [
            {
              title: "集团要闻",
              url: "/news/group-news",
            },
            {
              title: "媒体报道",
              url: "/news/media-report",
            },
            {
              title: "品牌影像",
              url: "/news/brand-image",
            },
            // {
            //   title: "品牌画册",
            //   url: "/news/brand-painting",
            // },
            {
              title: "社交媒体",
              url: "/news/social-media",
            },
          ],
        },
        {
          id: 5,
          title: "加入我们",
          url: "/invite/staff-mien",
          children: [
            // {
            //   title: "企业文化",
            //   url: "/invite/firm-culture",
            // },
            {
              title: "员工风采",
              url: "/invite/staff-mien",
            },
            {
              title: "我要应聘",
              url: "/invite/offer",
            },
            // {
            //   title: "招采平台",
            //   url: "/invite/platform",
            // },
          ],
        },
        {
          id: 6,
          title: "联系我们",
          url: "/relation",
        },
        
      ],
      isScroll: false,
      secondShow: false,
      seconds: [],
      height: "140px",
    };
  },
  computed: {
    logoImg() {
      if (this.activeId === 0) {
        return !this.isScroll
          ? require("../../assets/images/logo.png")
          : require("../../assets/images/logo-black.png");
      } else {
        return require("../../assets/images/logo-black.png");
      }
    },
  },
  methods: {
    route(item) {
      if (item.url != this.$route.fullPath) {
        this.secondShow = false;
        this.$router.push({
          path: item.url,
        });
      }
    },
    setTag(item) {
      this.activeId = item.id;
      this.secondShow = false;
      this.$router.push({
        path: item.url,
      });
    },
    enter(item, index) {
      let length = this.navs.length;
      if (item.children && item.children.length > 0) {
        if (this.activeId != index) {
          let secondsArray = Array(item.children.length).fill([]);

          item.children.forEach((val, idx) => {
            secondsArray.splice(idx, 1, val);
          });

          this.seconds = Array(index).fill([]).concat(secondsArray);
          let sLength = this.seconds.length;

          if (sLength > length) {
            switch (item.title) {
              case "新闻中心":
                this.seconds.splice(0, 3);
                break;

              default:
                break;
            }
            // // 表示超出数组长度
            // let num = sLength - length;
            // this.seconds.splice(0, num + 1);
          }
          this.secondShow = true;
        }
      }
    },
    leave(item) {
      this.secondShow = false;
    },
    enterSecond() {
      this.secondShow = true;
    },
    leaveSecond() {
      this.secondShow = false;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal) {
        let url = newVal.path;
        let urls = url.split("/");
        let idx = this.navs.findIndex((nav) => {
          let navUrls = nav.url.split("/");
          return navUrls[1] === urls[1];
        });
        this.activeId = idx=='-1' ? 6: this.navs[idx].id;
      },
    },
    secondShow: function (val) {
      if (!!val) {
        this.height = "224px";
      } else {
        this.height = "140px";
      }
    },
  },
  created() {
    const that = this;
    window.addEventListener("scroll", (e) => {
      this.secondShow = false;
      let scrollTop = document.documentElement.scrollTop;
      let scale = document.body.style.transform.slice(6, -1) || 1;
      this.$refs.header.style.top = scrollTop / scale + "px";
      if (scrollTop > 5 && !that.isScroll) {
        that.isScroll = true;
      } else if (scrollTop <= 5) {
        that.isScroll = false;
      }
    });
  },
};
</script>

<style scoped lang="less">
.my-header {
  width: 100%;
  // height: 105px;
  // height: 140px;
  // overflow: hidden;
  // background: rgb(255, 255, 255);
  z-index: 99;
  position: absolute;
  .my-header-cover {
    // position: fixed;
    left: 0;
    top: 0;
    right: 0;
    // height: 105px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    transition: 0.3s all;
    padding: 0 171px 0 160px;
    &.scroll-header {
      background: rgb(255, 255, 255);
      .nav .nav-item {
        color: #222;
        &.active {
          color: #e95506;
        }
      }
    }
  }
  .second-header {
    box-shadow: 0px 10px 16px 2px rgba(89, 89, 89, 0.23);
    position: absolute;
    top: 140px;
    width: 100%;
    height: 84px;
    left: 0;
    background: rgba(0,0,0,.6) !important;
    z-index: 99;
    padding-left: 535px;
    // opacity: 0.5;
    .second-nav {
      display: inline-block;
      line-height: 84px;
      width: 96px;
      font-size: 20px;
      color: #fff;
      margin-right: 78px;
      cursor: pointer;
      text-align: center;
    }
  }
  .logo {
    // width: 400px;
    // height: 105px;
    .img {
      // width: 140px;
      // height: 61px;
      width: 186px;
      height: 66px;
      overflow: hidden;
      // margin: 22px auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .nav {
    height: 100%;
    flex: 1;
    text-align: right;
    display: block;
    // height: 105px;
    // padding-left: 100px;
    
    .nav-item {
      display: inline-block;
      color: #222;
      // line-height: 105px;
      line-height: 140px;
      // font-size: 15px;
      font-size: 24px;
      font-weight: 500;
      // margin-right: 50px;
      margin-right: 78px;
      cursor: pointer;
      position: relative;
      transition: 0.3s all;
      font-family: "苹方-简 中黑体";
      src: url("../../assets/fonts/PINGFANG HEAVY.TTF");
      .line {
        position: absolute;
        // bottom: 25px;
        bottom: 30px;
        left: 50%;
        width: 0;
        transform: translateX(-50%);
        height: 4px;
        background: #e95506;
        transition: 0.3s width;
      }
      &.active {
        .line {
          // width: 16px;
          width: 22px;
        }
      }
    }
    .active {
      color: #e95506;
    }
  }
}
.isHome {
  // position: fixed;
  top: 0;
  background-color: transparent;
  .nav {
    .nav-item {
      // color: rgb(255, 255, 255);
    }
    .active {
      color: #e95506 !important;
    }
  }
}
</style>
