import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import MetaInfo from 'vue-meta-info'

import less from 'less'
import '../src/assets/css/font.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(less)
Vue.use(ElementUI);
Vue.use(MetaInfo)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0;
  next()
})

if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) window.location.href = "http://www.weaiove.com/mobile"

new Vue({
  router,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))  
    // document.dispatchEvent(new Event('custom-render-trigger'))
  }
}).$mount('#app')
