<template>
  <div class="my-footer">
    <div class="name">
      <div class="companyName">保定市爱城置业有限公司</div>
      <div class="address">
        地址：北京市丰台区国投财富广场2号楼18层
        <span class="ihone">联系电话：010-6336 6673 </span>
      </div>
    </div>
    <div class="info">
      <div class="statement" v-for='item in state' :key='item.id' @click="routeState(item.path)">法律声明</div>
      <div class="records">
        <a href="https://beian.miit.gov.cn">冀ICP备20017716号</a>
        <!-- <span>｜京公网安备11011011011</span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyFooter",
  data(){
    return{
      state:[
        {
          title:'法律声明',
          path: '/statement'
        }
      ]
    }
  },
  methods: {
    routeState(path) {
      this.$router.push({
        path:path,
      })
    },
  }
};
</script>

<style scoped lang="less">
@100: 100%;
.my-footer {
  width: @100;
  // height: 150px;
  height: 200px;
  background: #222;
  color: #fff;
  // font-size: 14px;
  font-size: 20px;
  font-weight: 400;
  padding: 70px 162px;
  overflow: hidden;
  .name {
    line-height: 28px;
    cursor: pointer;
    float: left;
    margin-right: 383px;
    .address{
      margin-top: 11px;
      .ihone {
        margin-left: 65px;
      }
    }
  }
  .info {
    line-height: 28px;
    float: left;
    cursor: pointer;
    .records {
      margin-top: 11px;
      a{
        color: #fff;
        text-decoration: none;
      }
    }
  }
}
</style>
