import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    redirect:'/home',
    children: [
      {
        // 首页
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home/Index.vue'),
      },
      {
        // 爱情地产
        path: '/company',
        component: () => import(/* webpackChunkName: "company" */ '../views/Company/Index.vue'),
        children: [
          {
            path: 'about',
            component: () => import(/* webpackChunkName: "company" */ '../views/Company/About.vue'),
            meta: {
              name: '集团简介',
              enTitle: 'PROFILES',
              url: '/company/about',
            } 
          },
          {
            path: 'concept',
            component: () => import(/* webpackChunkName: "company" */ '../views/Company/Concept.vue'),
            meta: {
              name: '品牌理念',
              enTitle: 'OPINION',
              url: '/company/concept',
            }
          },
          {
            path: 'layout',
            component: () => import(/* webpackChunkName: "company" */ '../views/Company/Layout.vue'),
            meta: {
              name: '战略布局',
              enTitle: 'LAYOUT',
              url: '/company/layout',
            }
          },
          {
            path: 'course',
            component: () => import(/* webpackChunkName: "company" */ '../views/Company/Course.vue'),
            meta: {
              name: '发展历程',
              enTitle: 'DEVELOP',
              url: '/company/course',
            }
          },
          {
            path: 'develop',
            component: () => import(/* webpackChunkName: "company" */ '../views/Company/Develop.vue'),
            meta: {
              name: '品牌荣誉',
              enTitle: 'AWARDS',
              url: '/company/develop',
            }
          },
        ]
      },
      {
        // 产品创新
        path: '/product',
        name: 'Product',
        component: () => import(/* webpackChunkName: "product" */ '../views/Product/Index.vue'),
        meta: {
          name: '低碳智慧城市运营商 构建多元价值生态',
          enTitle: 'INNOVATION',
          url: '/product',
        }
      },
      {
        // 业务版块
        path: '/business',
        component: () => import(/* webpackChunkName: "business" */ '../views/Business/Index.vue'),
        children: [
          {
            path: 'dwell',
            component: () => import(/* webpackChunkName: "company" */ '../views/Business/Dwell.vue'),
            meta: {
              name: '地产开发',
              enTitle: 'DEVELOPMENT',
              url: '/business/dwell',
            }
          },
          {
            path: 'operate',
            component: () => import(/* webpackChunkName: "company" */ '../views/Business/Operate.vue'),
            meta: {
              name: '商业运营',
              enTitle: 'OPERATION',
              url: '/business/operate',
            }
          },
          {
            path: 'estate',
            component: () => import(/* webpackChunkName: "company" */ '../views/Business/Estate.vue'),
            meta: {
              name: '物业服务',
              enTitle: 'PROPERTY',
              url: '/business/estate',
            }
          },
        ]
      },
      {
        // 新闻中心
        path: '/news',
        name: 'News',
        component: () => import(/* webpackChunkName: "news" */ '../views/News/Index.vue'),
        children: [
          {
            path: 'group-news',
            name: 'GroupNews',
            component: () => import(/* webpackChunkName: "GroupNews" */ '../views/News/GroupNews.vue'),
            meta: {
              name: '集团要闻',
              enTitle: 'NEWS',
              url: '/news/group-news',
            }
          },
          {
            path: 'media-report',
            name: 'MediaReport',
            component: () => import(/* webpackChunkName: "MediaReport" */ '../views/News/MediaReport.vue'),
            meta: {
              name: '媒体报道',
              enTitle: 'NEWS',
              url: '/news/media-report',
            }
          },
          {
            path: 'brand-image',
            name: 'BrandImage',
            component: () => import(/* webpackChunkName: "BrandImage" */ '../views/News/BrandImage.vue'),
            meta: {
              name: '品牌影像',
              enTitle: 'VIDEOS',
              url: '/news/brand-image',
            }
          },
          // {
          //   path: 'brand-painting',
          //   name: 'BrandPainting',
          //   component: () => import(/* webpackChunkName: "BrandPainting" */ '../views/News/BrandPainting.vue'),
          //   meta: {
          //     name: '品牌画册',
          //     enTitle: 'ALBUM',
          //     url: '/news/brand-painting',
          //   }
          // },
          {
            path: 'social-media',
            name: 'SocialMedia',
            component: () => import(/* webpackChunkName: "SocialMedia" */ '../views/News/SocialMedia.vue'),
            meta: {
              name: '社交媒体',
              enTitle: 'MEDIA',
              url: '/news/social-media',
            },
          },
          {
            path: 'news-details/:id',
            name: 'NewsDetails',
            component: () => import(/* webpackChunkName: "NewsDetails" */ '../views/News/NewsDetails.vue'),
            meta: {
              name: '新闻详情',
              enTitle: 'PROPERTY',
              url: '/news/news-details',
            },

          }
        ]
      },
      {
        // 加入我们
        path: '/invite',
        name: 'Invite',
        component: () => import(/* webpackChunkName: "invite" */ '../views/Invite/Index.vue'),
        children: [
          // {
          //   path: 'firm-culture',
          //   name: 'FirmCulture',
          //   component: () => import(/* webpackChunkName: "FirmCulture" */ '../views/Invite/FirmCulture.vue'),
          //   meta: {
          //     name: '企业文化',
          //     enTitle: 'PROPERTY',
          //     url: '/invite/firm-culture',
          //   }
          // },
          {
            path: 'staff-mien',
            name: 'StaffMien',
            component: () => import(/* webpackChunkName: "StaffMien" */ '../views/Invite/StaffMien.vue'),
            meta: {
              name: '员工风采',
              enTitle: 'STYLE',
              url: '/invite/staff-mien',
            }
          },
          {
            path: 'offer',
            name: 'Offer',
            component: () => import(/* webpackChunkName: "Offer" */ '../views/Invite/Offer.vue'),
            meta: {
              name: '我要应聘',
              enTitle: 'JOIN US',
              url: '/invite/offer',
            }
          },
          // {
          //   path: 'platform',
          //   name: 'Platform',
          //   component: () => import(/* webpackChunkName: "Platform" */ '../views/Invite/Platform.vue'),
          //   meta: {
          //     name: '招采平台',
          //     enTitle: 'PROPERTY',
          //     url: '/invite/platform',
          //   }
          // },
          {
            path: 'post-details/:id',
            name: 'PostDetails',
            component: () => import(/* webpackChunkName: "PostDetails" */ '../views/Invite/PostDetails.vue')
          }
        ]
      },
      {
        // 联系我们
        path: '/relation',
        name: 'Relation',
        component: () => import(/* webpackChunkName: "relation" */ '../views/Relation/Index.vue'),
        meta: {
          name: '各地公司',
          enTitle: 'ADDRESS',
          url: '/relation',
        }
      },
      {
        // 法律声明
        path: '/statement',
        name: 'statement',
        component: () => import(/* webpackChunkName: "home" */ '../views/statement/statement.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  mode:'history',
  base:'/',
  routes
})

export default router
