<template>
  <div class="home">
    <my-header />
    <router-view />
    <my-footer />
  </div>
</template>

<script>
import MyHeader from "../components/common/MyHeader.vue";
import MyFooter from "../components/common/MyFooter.vue";
export default {
  name: "Home",
  components: {
    MyHeader,
    MyFooter,
  },
  methods: {
    init() {
      var cw = 1920,
        ch = 1080;
      // var body = document.getElementById("body");
      var body = document.body;
      body.style.width = `${cw}px`;
      body.style.height = `${ch}px`;

      // 对body进行缩放
      function windowResize() {
        // 窗口宽高
        var w = window.innerWidth,
          h = window.innerHeight;

        if (w != 1920) {
          // 缩放比例
          // var r = w / cw < h / ch ? w / cw : h / ch;
          var r = w / cw;
          var rh = h / ch;
          window.scaleValue = {
            x: r,
            y: rh
          };         
          body.style.transform = `scale(${r})`;
          body.style.transformOrigin = 'left top'
          // 因为scale是以body的原中心点为基准进行缩放，所以缩放之后需要调整外边距，使其位于窗口的中心位置
          // body.style.marginLeft = -(cw - r * cw) / 2 + (w - r * cw) / 2 + "px";
          // body.style.marginTop = -(ch - r * ch) / 2 + (h - r * ch) / 2 + "px";
          // body.style.marginBottom = -(h > ch ? h : ch - r * ch) + "px";
          // body.style.marginRight = -(w > cw ? w : cw - r * cw) + "px";
        }
      }

      windowResize();
      // 监听窗口尺寸变化
      window.addEventListener("resize", windowResize);
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  position: relative;
}
</style>
